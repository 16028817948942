/* globals braintree */

import loggerHelper from '../loggerHelper';
import initBraintreeClient from '../braintree_blue/init';

let context = {};
let deviceDataToken;

const init = ctx => context = ctx;

const isDeviceDataScript = script => script.match(/data-collector/g);

const isDeviceDataScriptIncluded = scripts => scripts.some(isDeviceDataScript);

const initDeviceDataCollector = (braintreeToken) => {
  initBraintreeClient(braintreeToken).then(clientInstance => braintree.dataCollector.create({
    client: clientInstance,
    kount: true,
  })).then((dataCollectorInstance) => {
    braintreeDeviceDataReceived(dataCollectorInstance.deviceData);
  }).catch((err) => {
    loggerHelper.error('Device Data Collector error:', err);
  });
};

const braintreeDeviceDataReceived = (deviceData) => {
  deviceDataToken = deviceData;
  context.sendMessageToAllIframes({ action: 'BRAINTREE_DEVICE_DATA', data: { deviceData } });
};


const getDeviceDataToken = () => deviceDataToken;

export default {
  init,
  initDeviceDataCollector,
  isDeviceDataScriptIncluded,
  getDeviceDataToken,
};
