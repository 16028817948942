export default {
  AT: {
    changeLabels: { routingNumber: 'Bankleitzahl, BLZ', accountNumber: 'Kontonummer, Kto.' },
    disabledFields: ['branchCode'],
  },
  AU: {
    changeLabels: { branchCode: 'BSB number' },
    onlyLocalDetails: true,
    disabledFields: ['routingNumber'],
  },
  AX: {
    disabledFields: ['branchCode'],
  },
  BE: {
    changeLabels: { accountNumber: 'Rekeningnummer/Numéro de compte' },
    disabledFields: ['branchCode', 'routingNumber'],
  },
  CA: {
    changeLabels: { routingNumber: 'Institution number', branchCode: 'Transit number' },
    onlyLocalDetails: true,
  },
  CY: {
    changeLabels: { routingNumber: 'Kodikos Trapezas', accountNumber: 'Arithmos Logariasmou', branchCode: 'Kodikos Katastimatos' },
  },
  DE: {
    changeLabels: { accountNumber: 'Kontonummer', routingNumber: 'Bankleitzahl' },
    disabledFields: ['branchCode'],
  },
  DK: {
    changeLabels: { accountNumber: 'Kontonummer', routingNumber: 'Registreringsnummer' },
    disabledFields: ['branchCode'],
  },
  EE: {
    disabledFields: ['branchCode', 'routingNumber'],
  },
  ES: {
    changeLabels: { routingNumber: 'Código de entidad', accountNumber: 'Número de cuenta', branchCode: 'Código de oficina' },
  },
  FI: {
    disabledFields: ['branchCode'],
  },
  FR: {
    changeLabels: { routingNumber: 'Code banque', branchCode: 'Code guichet', accountNumber: 'Numéro de compte' },
  },
  GB: {
    changeLabels: { branchCode: 'Sort Code' },
    disabledFields: ['routingNumber'],
  },
  GF: {
    changeLabels: { routingNumber: 'Code banque', branchCode: 'Code guichet', accountNumber: 'Numéro de compte' },
  },
  GR: {
    changeLabels: { routingNumber: 'Kodikos Trapezas', branchCode: 'Kodikos Katastimatos', accountNumber: 'Arithmos Logariasmou' },
  },
  IE: {
    changeLabels: { branchCode: 'Sort code' },
    disabledFields: ['routingNumber'],
  },
  IT: {
    changeLabels: { routingNumber: 'Codice ABI', branchCode: 'CAB', accountNumber: 'Numero di conto' },
  },
  LT: {
    disabledFields: ['branchCode'],
  },
  LU: {
    disabledFields: ['branchCode'],
  },
  LV: {
    disabledFields: ['branchCode'],
  },
  MC: {
    changeLabels: { routingNumber: 'Code banque', branchCode: 'Code guichet', accountNumber: 'Numéro de compte' },
  },
  MT: {
    changeLabels: { routingNumber: 'Sort code', accountNumber: 'Numru tal‑kont bankarju' },
    disabledFields: ['branchCode'],
  },
  NL: {
    changeLabels: { accountNumber: 'Rekeningnummer' },
    disabledFields: ['branchCode'],
  },
  NZ: {
    changeLabels: { address2: 'Suburb' },
    onlyLocalDetails: true,
  },
  PL: {
    changeLabels: { routingNumber: 'Bank Identifier', accountNumber: 'Account Number' },
    disabledFields: ['branchCode'],
  },
  PT: {
    changeLabels: { routingNumber: 'Código de Banco', branchCode: 'Código de Balcão', accountNumber: 'Número de conta' },
  },
  SE: {
    changeLabels: { branchCode: 'Clearingnummer', accountNumber: 'Kontonummer' },
    onlyLocalDetails: true,
    disabledFields: ['routingNumber'],
  },
  SI: {
    disabledFields: ['branchCode'],
  },
  SK: {
    changeLabels: { routingNumber: 'Předčíslí', accountNumber: 'Číslo účtu' },
    disabledFields: ['branchCode'],
  },
  SM: {
    changeLabels: { routingNumber: 'Codice ABI', branchCode: 'CAB', accountNumber: 'Numero di conto' },
  },
};
