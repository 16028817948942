const JS_HOST_REGEXES = {
  prod: /(^https:\/\/.*chargify.(test|com)\/.*)chargify\.(unmin\.)*js(\?.*)?$/,
  dev: /(^https?:\/\/localhost:4000\/dist\/chargify\/)chargify\.js(\?.*)?$/,
  test: /(^https:\/\/js\.chargify\.test\/dist\/chargify\/)chargify\.js(\?.*)?$/,
  staging: /(^https:\/\/js\.staging-chargify\.com\/(v\/.*|latest)\/)chargify\.js(\?.*)?$/,
};

const SERVER_HOST_DOMAINS = [
  'chargify.com',
  'staging-chargify.com',
  'chargify.test',
  'test-chargify.com',
  'lvh.me',
];

// Only these are allowed to access the "Chargify._internal" fields
const INTERNAL_ALLOWED_CLIENT_HOST_DOMAINS = [
  'chargifypay.com',
  'staging-chargifypay.com',
  'chargifypay.test',
  'test-chargifypay.com',
  'localhost',
];

const matchJsHost = src =>
  src.match(JS_HOST_REGEXES.prod) ||
    src.match(JS_HOST_REGEXES.dev) ||
    src.match(JS_HOST_REGEXES.test) ||
    src.match(JS_HOST_REGEXES.staging);

const fetchDomain = (url) => {
  const urlParts = url.split('.');
  const domain = urlParts.slice(-2).join('.');
  const withoutPort = domain.replace(/:\d{4,}$/, '');

  return withoutPort;
};

const validateServerHost = url =>
  SERVER_HOST_DOMAINS.includes(fetchDomain(url));

const validateInternalAllowedClientHost = () => {
  const domain = fetchDomain(window.location.hostname);

  return INTERNAL_ALLOWED_CLIENT_HOST_DOMAINS.includes(domain);
};

export default {
  matchJsHost,
  validateServerHost,
  validateInternalAllowedClientHost,
};
