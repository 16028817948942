import arrayHelper from './arrayObjectHelper';

const mainIframeSelector = '#main-iframe';

const getIframeSelectors = ({ fields }) => {
  const selectors = [];

  Object.keys(fields).forEach((fieldName) => {
    const selector = fields[fieldName].selector;
    if (!arrayHelper.includes(selectors, selector)) {
      selectors.push(selector);
    }
  });

  return selectors;
};

const getIframeName = (type, selector) =>
  (selector === mainIframeSelector) ? `%chargify%${selector}` : `%${type}%${selector}`;

const isNameIncludesType = (name, type) => name && name.includes(`%${type}%`);

const dataIframeStyles = {
  display: 'none',
  border: 'none',
  height: '0px',
  width: '0px',
};

const createDataIframe = (primaryIframeSelector, src, id, iframeStyles = dataIframeStyles) =>
  new Promise((resolve) => {
    const iframeSelector = (primaryIframeSelector == null) ? document.body : document.querySelector(primaryIframeSelector);
    const iframe = document.createElement('iframe');

    Object.keys(iframeStyles).forEach((styleProperty) => {
      iframe.style[styleProperty] = iframeStyles[styleProperty];
    });

    iframeSelector.appendChild(iframe);

    iframe.contentWindow.location.replace(src);
    iframe.setAttribute('id', id);
    iframe.name = id;
    iframe.onload = () => resolve(iframe);
    iframe.contentWindow.document.close();
  });

const destroyDataIframe = (primaryIframeSelector, id, iframeElems, iframeId) => {
  const iframeSelector = (primaryIframeSelector == null) ? document.body : document.querySelector(primaryIframeSelector);
  const iframe = iframeSelector.querySelector(`iframe#${id}`);

  if (!iframe) return;

  iframe.remove();
  delete iframeElems[iframeId];
};

const rendersField = (fieldName, fields, iframeSelector) => {
  if (!fields[fieldName]) return false;

  return iframeSelector === fields[fieldName].selector;
};

export default {
  getIframeSelectors,
  getIframeName,
  rendersField,
  isNameIncludesType,
  createDataIframe,
  destroyDataIframe,
  mainIframeSelector,
};
