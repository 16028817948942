export default {
  card: {
    firstName: {
      label: 'First Name',
      required: false,
      autocomplete: 'cc-given-name',
      placeholder: 'John',
    },
    lastName: {
      label: 'Last Name',
      required: false,
      autocomplete: 'cc-family-name',
      placeholder: 'Doe',
    },
    number: {
      label: 'Card number',
      required: true,
      autocomplete: 'cc-number',
      placeholder: 'Card Number',
      maxlength: 19,
      formatter: 'creditCardNumber',
    },
    month: {
      label: 'Month',
      required: true,
      autocomplete: 'cc-exp-month',
      placeholder: 'MM',
      maxlength: 2,
    },
    year: {
      label: 'Year',
      required: true,
      autocomplete: 'cc-exp-year',
      placeholder: 'YYYY',
      maxlength: 4,
    },
    cvv: {
      label: 'CVV',
      required: false,
      autocomplete: 'cc-csc',
      placeholder: 'CVV',
      maxlength: 4,
    },
    deviceData: {
      label: 'Device Data',
      required: true,
      placeholder: '',
    },
    address: {
      label: 'Address',
      required: false,
      autocomplete: 'card address-line1',
      placeholder: '',
    },
    address2: {
      label: 'Address 2',
      required: false,
      autocomplete: 'card address-line2',
      placeholder: '',
    },
    city: {
      label: 'City',
      required: false,
      autocomplete: 'card address-level2',
      placeholder: '',
    },
    state: {
      label: 'State',
      required: false,
      autocomplete: 'card address-level1',
      placeholder: 'TX',
    },
    zip: {
      label: 'Zip Code',
      required: false,
      autocomplete: 'card postal-code',
      placeholder: '',
    },
    country: {
      label: 'Country',
      required: false,
      autocomplete: 'card country',
      placeholder: 'US',
    },
  },
  bank: {
    firstName: {
      label: 'First Name',
      required: false,
      autocomplete: 'bank given-name',
      placeholder: 'John',
    },
    lastName: {
      label: 'Last Name',
      required: false,
      autocomplete: 'bank family-name',
      placeholder: 'Doe',
    },
    bankName: {
      label: 'Bank Name',
      required: true,
      placeholder: '',
    },
    routingNumber: {
      label: 'Routing Number',
      required: true,
      placeholder: '',
    },
    accountNumber: {
      label: 'Account Number',
      required: true,
      placeholder: '',
    },
    accountType: {
      label: 'Account Type',
      required: false,
      placeholder: 'Please select',
      options: [
        { value: 'checking', text: 'Checking' },
        { value: 'savings', text: 'Savings' },
      ],
    },
    accountHolderType: {
      label: 'Account Holder Type',
      required: true,
      placeholder: 'Please select',
      options: [
        { value: 'personal', text: 'Personal' },
        { value: 'business', text: 'Business' },
      ],
    },
    address: {
      label: 'Address',
      required: false,
      autocomplete: 'bank address-line1',
      placeholder: '',
    },
    address2: {
      label: 'Address 2',
      required: false,
      autocomplete: 'bank address-line2',
      placeholder: '',
    },
    city: {
      label: 'City',
      required: false,
      autocomplete: 'bank address-level2',
      placeholder: '',
    },
    state: {
      label: 'State',
      required: false,
      autocomplete: 'bank address-level1',
      placeholder: 'TX',
    },
    zip: {
      label: 'Zip Code',
      required: false,
      autocomplete: 'bank postal-code',
      placeholder: '',
    },
    country: {
      label: 'Country',
      required: false,
      autocomplete: 'bank country',
      placeholder: 'US',
    },
  },
  gocardless: {
    address: {
      label: 'Address',
      required: true,
      autocomplete: 'gocardless address-line1',
      placeholder: '',
    },
    address2: {
      label: 'Address 2',
      required: false,
      autocomplete: 'gocardless address-line2',
      placeholder: '',
    },
    country: {
      autogiro: {
        label: 'Country',
        required: true,
        placeholder: 'Please select',
        options: [
          { value: 'SE', text: 'Sweden' },
        ],
      },
      bacs: {
        label: 'Country',
        required: true,
        placeholder: 'Please select',
        options: [
          { value: 'GB', text: 'United Kingdom' },
        ],
      },
      becs: {
        label: 'Country',
        required: true,
        placeholder: 'Please select',
        options: [
          { value: 'AU', text: 'Australia' },
        ],
      },
      becs_nz: {
        label: 'Country',
        required: true,
        placeholder: 'Please select',
        options: [
          { value: 'NZ', text: 'New Zealand' },
        ],
      },
      betalingsservice: {
        label: 'Country',
        required: true,
        placeholder: 'Please select',
        options: [
          { value: 'DK', text: 'Denmark' },
        ],
      },
      sepa: {
        label: 'Country',
        required: true,
        placeholder: 'Please select',
        options: [
          { value: 'AT', text: 'Austria' },
          { value: 'BE', text: 'Belgium' },
          { value: 'CY', text: 'Cyprus' },
          { value: 'EE', text: 'Estonia' },
          { value: 'FI', text: 'Finland' },
          { value: 'FR', text: 'France' },
          { value: 'GF', text: 'French Guiana' },
          { value: 'DE', text: 'Germany' },
          { value: 'GR', text: 'Greece' },
          { value: 'GP', text: 'Guadeloupe' },
          { value: 'IE', text: 'Ireland' },
          { value: 'IT', text: 'Italy' },
          { value: 'LV', text: 'Latvia' },
          { value: 'LT', text: 'Lithuania' },
          { value: 'LU', text: 'Luxembourg' },
          { value: 'MT', text: 'Malta' },
          { value: 'MQ', text: 'Martinique' },
          { value: 'YT', text: 'Mayotte' },
          { value: 'MC', text: 'Monaco' },
          { value: 'NL', text: 'Netherlands' },
          { value: 'PL', text: 'Poland' },
          { value: 'PT', text: 'Portugal' },
          { value: 'RE', text: 'Réunion' },
          { value: 'BL', text: 'Saint Barthélemy' },
          { value: 'MF', text: 'Saint Martin' },
          { value: 'SM', text: 'San Marino' },
          { value: 'SK', text: 'Slovakia' },
          { value: 'SI', text: 'Slovenia' },
          { value: 'ES', text: 'Spain' },
          { value: 'AX', text: 'Åland Islands' },
        ],
      },
      pad: {
        label: 'Country',
        required: true,
        placeholder: 'Please select',
        options: [
          { value: 'CA', text: 'Canada' },
        ],
      },
    },
    city: {
      label: 'City',
      required: true,
      autocomplete: 'gocardless address-level2',
      placeholder: '',
    },
    state: {
      label: 'State',
      required: true,
      autocomplete: 'gocardless address-level1',
      placeholder: 'TX',
    },
    zip: {
      label: 'Zip Code',
      required: true,
      autocomplete: 'gocardless postal-code',
      placeholder: '',
    },
    accountHolderType: {
      label: 'Account Holder Type',
      required: true,
      placeholder: 'Please select',
      options: [
        { value: 'personal', text: 'Personal' },
        { value: 'business', text: 'Business' },
      ],
    },
    firstName: {
      label: 'First Name',
      required: true,
      autocomplete: 'gocardless given-name',
      placeholder: 'John',
    },
    lastName: {
      label: 'Last Name',
      required: true,
      autocomplete: 'gocardless family-name',
      placeholder: 'Doe',
    },
    swedishIdentityNumber: {
      label: 'Swedish Identity Number',
      required: true,
      placeholder: '',
    },
    danishIdentityNumber: {
      label: 'CPR Number',
      required: true,
      placeholder: '',
    },
    email: {
      label: 'Email Address',
      required: true,
      autocomplete: 'gocardless email',
      placeholder: '',
    },
    phone: {
      label: 'Phone Number',
      required: true,
      autocomplete: 'gocardless tel',
      placeholder: '',
    },
    bankName: {
      label: 'Bank Name',
      required: true,
      placeholder: '',
    },
    bankIban: {
      label: 'IBAN',
      required: true,
      placeholder: '',
    },
    accountNumber: {
      label: 'Account Number',
      required: true,
      placeholder: '',
    },
    branchCode: {
      label: 'Branch Code',
      required: true,
      placeholder: '',
    },
    routingNumber: {
      label: 'Bank Code',
      required: true,
      placeholder: '',
    },
  },
  apple_pay: {
    firstName: {
      label: 'First Name',
      required: false,
      autocomplete: 'given-name',
      placeholder: 'John',
    },
    lastName: {
      label: 'Last Name',
      required: false,
      autocomplete: 'family-name',
      placeholder: 'Doe',
    },
    address: {
      label: 'Address',
      required: false,
      autocomplete: 'address-line1',
      placeholder: '',
    },
    address2: {
      label: 'Address 2',
      required: false,
      autocomplete: 'address-line2',
      placeholder: '',
    },
    city: {
      label: 'City',
      required: false,
      autocomplete: 'address-level2',
      placeholder: '',
    },
    state: {
      label: 'State',
      required: false,
      autocomplete: 'address-level1',
      placeholder: 'TX',
    },
    zip: {
      label: 'Zip Code',
      required: false,
      autocomplete: 'postal-code',
      placeholder: '',
    },
    country: {
      label: 'Country',
      required: false,
      autocomplete: 'country',
      placeholder: 'US',
    },
    paymentMethodNonce: {
      label: 'Payment Method Nonce',
      required: true,
      placeholder: '',
    },
    deviceData: {
      label: 'Device Data',
      required: true,
      placeholder: '',
    },
  },
  pay_pal: {
    firstName: {
      label: 'First Name',
      required: false,
      autocomplete: 'given-name',
      placeholder: 'John',
    },
    lastName: {
      label: 'Last Name',
      required: false,
      autocomplete: 'family-name',
      placeholder: 'Doe',
    },
    address: {
      label: 'Address',
      required: false,
      autocomplete: 'address-line1',
      placeholder: '',
    },
    address2: {
      label: 'Address 2',
      required: false,
      autocomplete: 'address-line2',
      placeholder: '',
    },
    city: {
      label: 'City',
      required: false,
      autocomplete: 'address-level2',
      placeholder: '',
    },
    state: {
      label: 'State',
      required: false,
      autocomplete: 'address-level1',
      placeholder: 'TX',
    },
    zip: {
      label: 'Zip Code',
      required: false,
      autocomplete: 'postal-code',
      placeholder: '',
    },
    country: {
      label: 'Country',
      required: false,
      autocomplete: 'country',
      placeholder: 'US',
    },
    payPalEmail: {
      label: 'PayPal Email',
      required: true,
      placeholder: '',
    },
    paymentMethodNonce: {
      label: 'Payment Method Nonce',
      required: true,
      placeholder: '',
    },
    deviceData: {
      label: 'Device Data',
      required: true,
      placeholder: '',
    },
  },
  stripe_connect_sepa: {
    firstName: {
      label: 'First Name',
      required: true,
      autocomplete: 'bank given-name',
      placeholder: 'John',
    },
    lastName: {
      label: 'Last Name',
      required: true,
      autocomplete: 'bank family-name',
      placeholder: 'Doe',
    },
    email: {
      label: 'Email Address',
      required: true,
      autocomplete: 'email',
      placeholder: '',
    },
    bankName: {
      label: 'Bank Name',
      required: false,
      placeholder: '',
    },
    bankIban: {
      label: 'IBAN',
      required: true,
      placeholder: '',
    },
    accountType: {
      label: 'Account Type',
      required: false,
      placeholder: 'Please select',
      options: [
        { value: 'checking', text: 'Checking' },
        { value: 'savings', text: 'Savings' },
      ],
    },
    accountHolderType: {
      label: 'Account Holder Type',
      required: true,
      placeholder: 'Please select',
      options: [
        { value: 'personal', text: 'Personal' },
        { value: 'business', text: 'Business' },
      ],
    },
    address: {
      label: 'Address',
      required: false,
      autocomplete: 'bank address-line1',
      placeholder: '',
    },
    address2: {
      label: 'Address 2',
      required: false,
      autocomplete: 'bank address-line2',
      placeholder: '',
    },
    city: {
      label: 'City',
      required: false,
      autocomplete: 'bank address-level2',
      placeholder: '',
    },
    state: {
      label: 'State',
      required: false,
      autocomplete: 'bank address-level1',
      placeholder: 'TX',
    },
    zip: {
      label: 'Zip Code',
      required: false,
      autocomplete: 'bank postal-code',
      placeholder: '',
    },
    country: {
      label: 'Country',
      required: false,
      autocomplete: 'bank country',
      placeholder: 'US',
    },
  },
  stripe_connect_becs: {
    firstName: {
      label: 'First Name',
      required: true,
      autocomplete: 'bank given-name',
      placeholder: 'John',
    },
    lastName: {
      label: 'Last Name',
      required: true,
      autocomplete: 'bank family-name',
      placeholder: 'Doe',
    },
    email: {
      label: 'Email Address',
      required: true,
      autocomplete: 'email',
      placeholder: '',
    },
    bankName: {
      label: 'Bank Name',
      required: false,
      placeholder: '',
    },
    branchCode: {
      label: 'BSB',
      required: true,
      placeholder: '',
    },
    accountNumber: {
      label: 'Account Number',
      required: true,
      placeholder: '',
    },
    accountType: {
      label: 'Account Type',
      required: false,
      placeholder: 'Please select',
      options: [
        { value: 'checking', text: 'Checking' },
        { value: 'savings', text: 'Savings' },
      ],
    },
    accountHolderType: {
      label: 'Account Holder Type',
      required: true,
      placeholder: 'Please select',
      options: [
        { value: 'personal', text: 'Personal' },
        { value: 'business', text: 'Business' },
      ],
    },
    address: {
      label: 'Address',
      required: false,
      autocomplete: 'bank address-line1',
      placeholder: '',
    },
    address2: {
      label: 'Address 2',
      required: false,
      autocomplete: 'bank address-line2',
      placeholder: '',
    },
    city: {
      label: 'City',
      required: false,
      autocomplete: 'bank address-level2',
      placeholder: '',
    },
    state: {
      label: 'State',
      required: false,
      autocomplete: 'bank address-level1',
      placeholder: 'TX',
    },
    zip: {
      label: 'Zip Code',
      required: false,
      autocomplete: 'bank postal-code',
      placeholder: '',
    },
    country: {
      label: 'Country',
      required: false,
      autocomplete: 'bank country',
      placeholder: 'US',
    },
  },
};
