import defaults from '../config/fieldDefaults';
import arrayObjectHelper from '../helpers/arrayObjectHelper';
import defaultHiddenFields from './hidden_fields';

const addSelectorToFields = function (userOptions) {
  if (userOptions.selector) {
    Object.keys(userOptions.fields).forEach((fieldName) => {
      if (!userOptions.fields[fieldName].selector) {
        userOptions.fields[fieldName].selector = userOptions.selector;
      }
    });
  }

  return userOptions;
};

const getPrimaryIframeSelector = function (options) {
  if (options.fields == null) return;

  if (options.type === 'card' && !options.deviceData) {
    return options.fields.number.selector;
  }

  if (['apple_pay', 'pay_pal'].includes(options.type)) {
    return options.fields.paymentMethodNonce.selector;
  }

  if (options.deviceData) {
    return options.fields.deviceData.selector;
  }

  if (options.type === 'stripe_connect_sepa') {
    return options.fields.bankIban.selector;
  }

  if (options.type === 'stripe_connect_becs') {
    return options.fields.accountNumber.selector;
  }

  if (options.type === 'direct_debit') {
    return;
  }

  return options.fields.accountNumber.selector;
};

const isPrimaryIframe = function (options) {
  return options.selfSelector === getPrimaryIframeSelector(options);
};

const shouldAddField = function (fieldName, defaultField, userOptions) {
  if (defaultField.required) {
    return true;
  }

  return userOptions.fields && userOptions.fields[fieldName];
};

const isInlineForm = function (fieldsToCheck, type) {
  const fields = { ...fieldsToCheck };
  delete fields.deviceData;

  if (type !== 'card') return false;

  const length = Object.keys(fields).length;
  if ((length === 3 && fields.number && fields.month && fields.year) ||
        (length === 4 && fields.number && fields.month && fields.year && fields.cvv)) {
    const selectors = [];
    Object.keys(fields).forEach((fieldName) => {
      if (!arrayObjectHelper.includes(selectors, fields[fieldName].selector)) {
        selectors.push(fields[fieldName].selector);
      }
    });

    return selectors.length === 1;
  }

  return false;
};

const selectorForHiddenFields = function (fieldName, userOptions) {
  const typeInTypesRequiringHiddenFields = Object.keys(defaultHiddenFields).includes(userOptions.type);
  const fieldNameInHiddenFields = typeInTypesRequiringHiddenFields && defaultHiddenFields[userOptions.type].includes(fieldName);

  if (typeInTypesRequiringHiddenFields && fieldNameInHiddenFields && !userOptions.selector) {
    return userOptions.fields[Object.keys(userOptions.fields)[Object.keys(userOptions.fields).length - 1]].selector;
  }
};

const getFieldData = function (fieldName, defaultField, userOptions) {
  const overrides = (userOptions.fields && userOptions.fields[fieldName]) || {};

  return {
    name: fieldName, // Doesn't always match the value recommended by Google - may break autocomplete
    required: defaultField.required ? true : !!overrides.required,
    label: overrides.label || defaultField.label,
    placeholder: overrides.placeholder || defaultField.placeholder,
    message: overrides.message || null,
    maxlength: overrides.maxlength || defaultField.maxlength || false,
    formatter: defaultField.formatter,
    selector: overrides.selector || userOptions.selector || selectorForHiddenFields(fieldName, userOptions),
    style: overrides.style || null,
    autocomplete: overrides.autocomplete || defaultField.autocomplete || null,
    options: defaultField.options || null,
  };
};

const getNormalizedOptions = function (userOptions, skipDefaultFields = false) {
  if (skipDefaultFields) return userOptions;

  const defaultFields = defaults[userOptions.type];
  const fields = {};

  if (defaultFields == null) return userOptions;

  // to keep the order of the user, 1st we loop through those fields
  if (userOptions.fields) {
    Object.keys(userOptions.fields).forEach((fieldName) => {
      fields[fieldName] = getFieldData(fieldName, defaultFields[fieldName], userOptions);
    }, this);
  }

  // then we add the required ones if they are not present yet
  Object.keys(defaultFields).forEach((fieldName) => {
    if (userOptions.type === 'gocardless' || (!fields[fieldName] && shouldAddField(fieldName, defaultFields[fieldName], userOptions))) {
      if (userOptions.type === 'gocardless' && fieldName === 'country') {
        const scheme = userOptions.scheme || 'sepa'; // sepa is just initial setting, it will be removed
        fields[fieldName] = getFieldData(fieldName, defaultFields[fieldName][scheme], userOptions);
      } else {
        fields[fieldName] = getFieldData(fieldName, defaultFields[fieldName], userOptions);
      }
    }
  }, this);

  removeUnessesaryFieldsForGoCardlessScheme(userOptions, fields);
  userOptions.fields = fields;
  userOptions.inline = isInlineForm(fields, userOptions.type);

  return userOptions;
};

const removeUnessesaryFieldsForGoCardlessScheme = (userOptions, fields) => {
  if (userOptions.type !== 'gocardless' || userOptions.scheme == null) return;

  if (userOptions.scheme !== 'becs_nz') {
    if (!Object.keys(userOptions.fields || {}).includes('email')) delete fields.email;
    if (!Object.keys(userOptions.fields || {}).includes('phone')) delete fields.phone;
  }

  if (userOptions.scheme !== 'autogiro') {
    if (!Object.keys(userOptions.fields || {}).includes('swedishIdentityNumber')) delete fields.swedishIdentityNumber;
  }

  if (userOptions.scheme !== 'betalingsservice') {
    if (!Object.keys(userOptions.fields || {}).includes('danishIdentityNumber')) delete fields.danishIdentityNumber;
  }
};

export default {
  getFieldData: getFieldData,
  shouldAddField: shouldAddField,
  getNormalizedOptions: getNormalizedOptions,
  addSelectorToFields: addSelectorToFields,
  getPrimaryIframeSelector: getPrimaryIframeSelector,
  isPrimaryIframe: isPrimaryIframe,
};
